import * as React from "react";
import Services from "../components/Sections/Services";
import SEO from "../components/Layout/SEO";

const ServicesPage = () => {
  return (
    <>
      <SEO
        title="What I do"
        description="I have over 5+ years professional experience in front-end development and WordPress development"
      />
      <Services />
    </>
  );
};
export default ServicesPage;

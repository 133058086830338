import { StaticImage } from "gatsby-plugin-image";
import React, { Component } from "react";
//https://www.netlify.com/

export class Services extends Component {
  render() {
    return (
      <div className="mx-5 sm:mx-auto">
        <div className="text-center mt-10 mb-10 sm:mb-20 relative z-10 lg:w-3/5 mx-auto">
          <h2 className="font-black text-4xl md:text-5xl m-4 dark:text-white">
            What I do?
          </h2>
          <p className="text-lg sm:text-xl md:text-1xl text-gray-500 dark:text-gray-300 mb-10 lg:px-2">
            I create websites and applications with attention to detail and a
            faithful rendering to the design.
          </p>
        </div>
        <div className="services_section relative z-10 xl:w-4/5 mx-auto flex flex-col gap-10 flex-col lg:flex-row mb-20 lg:mb-32 lg:px-10">
          <div className="lg:w-3/5 flex justify-center items-center">
            <StaticImage
              className="shadow-xl rounded"
              src="../../images/services/pushing-to-pixels.png"
              alt="Pushing pixels to billion of users."
            />
          </div>
          <div className="flex justify-center items-start flex-col lg:w-2/5">
            <h3 className="text-3xl mb-2 dark:text-white font-bold">
              Pushing pixels to billion of users.
            </h3>
            <p className="dark:text-gray-300">
              In order to have a rendering as close as possible to the web
              designer's mockup, I use Zeplin. This software / webapp allows you
              to export code for programming language (sass, less, react,
              android, swift). I am also familiar with Figma and Invision
              inspect.
            </p>
          </div>
        </div>
        <div className="services_section relative z-10 xl:w-4/5 mx-auto flex gap-10 flex-col lg:flex-row-reverse mb-20 lg:mb-32 lg:px-10">
          <div className="lg:w-3/5 flex justify-center items-center">
            <StaticImage
              className="shadow-xl rounded"
              alt="Developing high custom themes for WordPress"
              src="../../images/services/foxcodestudio-admin.png"
            />
          </div>
          <div className="flex justify-center items-start flex-col lg:w-2/5">
            <h3 className="text-3xl mb-2 dark:text-white font-bold">
              Developing high custom themes for WordPress
            </h3>
            <p className="dark:text-gray-300">
              Building a WordPress theme can be much more difficult than it
              seems at first. The most common problem in junior front-end
              developers is writing too many styles. Repeating code and
              modifying it too much may cause regressions to appear all over the
              place. Defining a good architecture from the start is paramount to
              success. And this is why a good style guide is important for large
              sites. That way I maintain just one component and it’s reused
              across the site. If I create a new component, then you would end
              up with tons of very similar but still different components that
              would be a hell to maintain.{" "}
            </p>
          </div>
        </div>
        <div className="services_section relative z-10  xl:w-4/5 mx-auto flex gap-10 flex-col lg:flex-row mb-20 lg:mb-32 lg:px-10">
          <div className="lg:w-3/5 flex justify-center items-center">
            <StaticImage
              className="shadow-xl rounded"
              src="../../images/services/gatsby_vs_next.jpg"
              alt="Building & Deploying Gatsby/Next Site to Netify or Vercel"
            />
          </div>
          <div className="flex justify-center items-start flex-col lg:w-2/5">
            <h3 className="text-3xl mb-2 dark:text-white font-bold">
              Building & Deploying Gatsby/Next Site to Netify or Vercel
            </h3>
            <p className="dark:text-gray-300">
              It's Babel and integrates with React for developing single-page
              apps. This makes server-side rendering very easy. Apart from that,
              Next.js and Gatsby.js support static export, pre-rendering and has
              many more nice features like automatic building size optimization,
              faster developer compilation, and a preview mode. Using Next.js or
              Gastby.js is a good approach if you are building a content-driven
              web app.
            </p>
          </div>
        </div>
        <div className="services_section relative z-10 xl:w-4/5 mx-auto flex gap-10 flex-col lg:flex-row-reverse mb-20 lg:mb-32 lg:px-10">
          <div className="lg:w-2/5 flex justify-center items-center ">
            <StaticImage
              className="rounded"
              alt="Testing with cross-browsers"
              src="../../images/services/browser-logos.png"
            />
          </div>
          <div className="flex justify-center items-start flex-col lg:w-3/5">
            <h3 className="text-3xl mb-2 dark:text-white font-bold">
              Testing with cross-browsers
            </h3>
            <p className="dark:text-gray-300">
              The cross-browser testing process is iterative. So much so that I
              am constantly on the lookout for the site's behavior on the most
              used browsers on the market.
            </p>
          </div>
        </div>
        <div className="services_section relative z-10 xl:w-4/5 mx-auto flex gap-10 flex-col lg:flex-row mb-20 lg:mb-32 lg:px-10">
          <div className="lg:w-3/5 flex justify-center items-center">
            <StaticImage
              className="shadow-xl rounded"
              alt="Developing & Publish app to Google Play and Apple Store"
              src="../../images/services/apple-google-store.jpg"
            />
          </div>
          <div className="flex justify-center items-start flex-col lg:w-2/5">
            <h3 className="text-3xl mb-2 dark:text-white font-bold">
              Developing & Publish app to Google Play and Apple Store.
            </h3>
            <p className="dark:text-gray-300">
              A few years ago, Facebook officially announced React Native, a
              powerful framework with the promise of cross-platform
              compatibility. React Native allows me to reuse the codebase (or
              just a part of it) between iOS and Android. In practice, full
              cross-platform development is possible to some extent, depending
              on how many native modules I use in your application.
            </p>
          </div>
        </div>
        <div className="services_section relative z-10  xl:w-4/5 mx-auto flex  gap-10 flex-col lg:flex-row-reverse mb-20 lg:mb-32 lg:px-10">
          <div className="lg:w-3/5 flex justify-center items-center">
            <StaticImage
              className="rounded shadow-xl"
              alt="Optimizing website to load very fast"
              src="../../images/services/pagespeed-insights.png"
            />
          </div>
          <div className="flex justify-center items-start flex-col lg:w-2/5">
            <h3 className="text-3xl mb-2 dark:text-white font-bold">
              Optimizing website to load very fast
            </h3>
            <p className="dark:text-gray-300">
              In fact, 47% of consumers expect websites to load in two seconds
              or less. And 40% will abandon a page that takes three or more
              seconds. Now that Google takes speed into consideration when
              ranking sites your load times can also influence how easily users
              can find you in the first place. I've optimized over 30 sites and
              can help make yours load lightning fast too.
            </p>
          </div>
        </div>
        <div className="services_section relative z-10  xl:w-4/5 mx-auto flex gap-10 flex-col lg:flex-row mb-20 lg:mb-32 lg:px-10">
          <div className="lg:w-3/5 flex justify-center items-center">
            <StaticImage
              className="shadow-xl rounded"
              alt="Email Development"
              src="../../images/services/emails.jpg"
            />
          </div>
          <div className="flex justify-center items-start flex-col lg:w-2/5">
            <h3 className="text-3xl font-bold mb-2 dark:text-white">
              Email Development
            </h3>
            <p className="dark:text-gray-300">
              Good emails are visually appealing and have engaging content. For
              each client and project, we create beautiful email designs that
              acts as marketing masterpiece in both regards. I use HTML5, media
              queries and other inline CSS declarations to make that happen.
              From font size to background color your email will be well built
              and robust. My aim is to provide you with a product that adapts to
              email clients and devices, having the widest possible reach.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Services;
